/**
   gatsby-browser.js

    cfr. https://www.gatsbyjs.com/docs/browser-apis/

    see tests & trials commented in/_backup/gatsby-browser_sw-listeners.js

 */

// ES6 import not compatible with JS export.x = require('')
// require('./src/styles/style.css')
import './src/styles/style.css'
import './src/styles/wizard.css'

// Both gatsby-browser.js and gatsby-ssr.js have access to this hook and it’s recommended to wrap both with our provider
//      solution from https://www.digitalocean.com/community/tutorials/gatsbyjs-state-management-in-gatsby
import ContextProvider from './src/components/ContextProvider'

// exports.onInitialClientRender = require('./src/gatsby/browser/onInitialClientRender') // onInitialClientRender DEPRECATED
export const wrapRootElement = ContextProvider