import React, { FC, useEffect, useState } from "react"

import { IAppContext } from '../types/index'
import { store, links } from '../constants'
import {
    getLanguageFromStore,
    isServiceWorkerSupported,
    linkManifestFile,
    navigateTo,
    saveCacheFlagToStore,
    isCacheFlaggedInStore,
    saveLanguageToStore
} from '../utils'

const getPropertyValue = (sourceObject: any, property: string, defaultValue: any) => {
    let result = defaultValue
    if(!!sourceObject && sourceObject.hasOwnProperty(property)) result = sourceObject[property]
    return result
}

const initNavigationLink = () => {

    // location, document and sessionStorage browser refs fail at 'gatsby build' because browser not yet available at build
    // (as opposed to 'gatsby develop') check undefined or use siteMetadata for url prefix, see:
    //      https://css-tricks.com/how-to-the-get-current-page-url-in-gatsby/
    //      https://www.gatsbyjs.com/docs/overview-of-the-gatsby-build-process/#build-time-vs-runtime

    const path = typeof location !== 'undefined' ? location.pathname : ''
    // console.log('# ContextProvider path ['+path+']')

    // if a non-home url is opened as the 1st of the session, this one must become the current link
    // so that the correct menu-item becomes underlined
    let currentLink = '/'
    links.map((link) => {
        if(path.startsWith(link.to)) currentLink = link.to
    })

    // console.log('# ContextProvider initNavigationLink() - path['+path+'] currentLink['+currentLink+']')

    // sessionStorage needed to persist navigationLink after page refreshes
    //      not possible with React Context, useState, useReducer (see doc in backup/ContextProvider)
    // using sessionStorage in stead of localStorage, which is saved after browser exit and not re-fetched at browser launch
    //      clear at browser exit via window.onunload = () => { window.MyStorage.clear() }
    //      https://codesandbox.io/s/smoosh-cherry-thdsm?file=/src/InfoContext.js
    //      https://stackoverflow.com/questions/53453861/react-context-api-persist-data-on-page-refresh
    let stored = {}
    if(typeof sessionStorage !== 'undefined') {
        stored = JSON.parse(sessionStorage.getItem(store.NAVIGATION_LINK) as string)
    }
    const result = {
        previousLink: getPropertyValue(stored, 'previousLink', ''),
        previousScrollTop: getPropertyValue(stored, 'previousScrollTop', 0),
        currentLink: getPropertyValue(stored, 'currentLink', currentLink),
        currentScrollTop: getPropertyValue(stored, 'currentScrollTop', 0)
    }
    // console.log('# ContextProvider initNavigationLink() - result['+JSON.stringify(result)+']')
    return result
}

// React Context API with Gatsby examples:
//      https://www.digitalocean.com/community/tutorials/gatsbyjs-state-management-in-gatsby
//      https://www.gatsbyjs.com/blog/2019-01-31-using-react-context-api-with-gatsby/
// React Context API (no Gatsby) example:
//      https://www.savaslabs.com/blog/using-react-global-state-hooks-and-context
//      https://medium.com/better-programming/react-global-component-with-hooks-d1471488cf73
//      https://codesandbox.io/s/react-simple-usecontext-s4mdf?from-embed=&file=/src/index.js
export const AppContext = React.createContext<IAppContext>({} as IAppContext)

const ContextProvider: FC = props => {
    // declare all app global variables and hooks to set the variable state
    // (!) 'lang' state prop needed to trigger current page rerender when language change via globe icon
    const [lang, setLang] = useState(getLanguageFromStore())
    const [navigationLink, setNavigationLink] = useState(initNavigationLink())
    // (!) see doc @ Utils.tsx linkManifestFile()
    // (!) FAIL 29/11/2022
    // const [manifestFileLinked, setManifestFileLinked] = useState(linkManifestFile())
    // console.log('# ContextProvider - manifestFileLinked['+manifestFileLinked+']')

    const setLanguage = (lang: string) => {
        console.log('# ContextProvider setLanguage() - call saveLanguageToStore['+lang+']')
        saveLanguageToStore(lang)
        setLang(lang)
    }

    //console.log('# ContextProvider - navigationLink['+JSON.stringify(navigationLink)+']')
    // (!) updateNavigationLink() NOT SURE only works with Gatsby Link but fails with a href oncClick
    const updateNavigationLink = (previousLink: string, previousScrollTop: number, currentLink: string, currentScrollTop: number) => {
        const updateObj = {
            previousLink: previousLink,
            previousScrollTop: previousScrollTop,
            currentLink: currentLink,
            currentScrollTop: currentScrollTop
        }
        sessionStorage.setItem(store.NAVIGATION_LINK, JSON.stringify(updateObj))
        //console.log('# ContextProvider updateNavigationLink() - updateObj['+JSON.stringify(updateObj)+']')
        setNavigationLink(updateObj)
    }

//    useEffect(() => {
        // if(!isServiceWorkerSupported()) {
        //     console.log('# ContextProvider - SW NOT SUPPORTED > showing spinner 3 seconds')
        //     setCachingFalseAfterTimeout()
        // }
        // else {
        //     // TODO how ever to be sure below is robust over all older browseres and Safari
        //     //    and will never keep hanging in state isCaching true
        //     navigator.serviceWorker.ready.then((registration) => {
        //         // Listen to the 'install' event
        //         registration.installing?.addEventListener('statechange', (event) => {
        //             if (event.target.state === 'installed') {
        //                 setCachingFalseAfterTimeout()
        //             }
        //         });
        //
        //         // Listen to the 'activate' event
        //         registration.addEventListener('statechange', (event) => {
        //             if (event.target.state === 'activated') {
        //                 setCachingFalseAfterTimeout()
        //             }
        //         });
        //
        //         // If the service worker is already activated
        //         if (registration.active) {
        //             setCachingFalseAfterTimeout()
        //         }
        //     })
        // }
 //   }, [])

    const defaultContext = {
        navigationLink,
        updateNavigationLink,
        lang,
        setLanguage
    }

    return (
        <AppContext.Provider value={defaultContext}>
            {props.children}
        </AppContext.Provider>
    )
}

// @ts-ignore
export default ({ element }) => (
    <ContextProvider>
        {element}
    </ContextProvider>
)