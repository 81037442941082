exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-deprecated-tsx": () => import("./../../../src/pages/index_DEPRECATED.tsx" /* webpackChunkName: "component---src-pages-index-deprecated-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-number-input-tsx": () => import("./../../../src/pages/number-input.tsx" /* webpackChunkName: "component---src-pages-number-input-tsx" */),
  "component---src-pages-qrcode-scanner-tsx": () => import("./../../../src/pages/qrcode-scanner.tsx" /* webpackChunkName: "component---src-pages-qrcode-scanner-tsx" */),
  "component---src-pages-wizard-initial-tsx": () => import("./../../../src/pages/wizard-initial.tsx" /* webpackChunkName: "component---src-pages-wizard-initial-tsx" */),
  "component---src-pages-wizard-standalone-tsx": () => import("./../../../src/pages/wizard-standalone.tsx" /* webpackChunkName: "component---src-pages-wizard-standalone-tsx" */),
  "component---src-templates-artwork-tsx": () => import("./../../../src/templates/artwork.tsx" /* webpackChunkName: "component---src-templates-artwork-tsx" */),
  "component---src-templates-info-point-tsx": () => import("./../../../src/templates/infoPoint.tsx" /* webpackChunkName: "component---src-templates-info-point-tsx" */),
  "component---src-templates-info-points-tsx": () => import("./../../../src/templates/infoPoints.tsx" /* webpackChunkName: "component---src-templates-info-points-tsx" */),
  "component---src-templates-paintings-tsx": () => import("./../../../src/templates/paintings.tsx" /* webpackChunkName: "component---src-templates-paintings-tsx" */),
  "component---src-templates-treasury-room-tsx": () => import("./../../../src/templates/treasuryRoom.tsx" /* webpackChunkName: "component---src-templates-treasury-room-tsx" */),
  "component---src-templates-treasury-tsx": () => import("./../../../src/templates/treasury.tsx" /* webpackChunkName: "component---src-templates-treasury-tsx" */)
}

