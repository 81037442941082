
// (!) REMARK: links array order of entries is fixed, changing order will break code (e.g. NavigationLink checks for underline)
module.exports.links = [
    { to: '/home', label: { nl: 'Start', en: 'Start', fr: 'Accueil' }, icon: 'home' },
    // { to: '/cathedral', label: { nl: 'Kathedraal', en: 'Cathedral', fr: 'Cathédrale' }, icon: 'stainedGlass' },
    // label 'Kathedraal infopunten' is te breed, 'infopunten' verschijnt niet
    // { to: '/infopoints', label: { nl: 'Kathedraal infopunten', en: 'Cathedral infopoints', fr: 'Cathédrale points d\'info' }, icon: 'infoPoint' },
    { to: '/infopoints', label: { nl: 'Infopunten', en: 'Infopoints', fr: 'Points d\'info' }, icon: 'infoPoint' },
    { to: '/ambulatory-paintings', label: { nl: 'Schilderijen', en: 'Paintings', fr: 'Tableaux' }, icon: 'paintings' },
    { to: '/qrcode-scanner', label: { nl: 'Scan QRC', en: 'Scan QRC', fr: 'Scan QRC' }, icon: 'scan' },
    { to: '/treasury', label: { nl: 'Schatkamer', en: 'Treasury', fr: 'Trésor' }, icon: 'rooms' },
    { to: '/number-input', label: { nl: 'Nummer', fr: 'Numéro', en: 'Number' }, icon: 'artworkNumber' }
]

module.exports.titles = {
    // treasury: { nl: 'Sint-Salvators Schatkamer', en: 'St Saviour\'s Treasury', fr: 'Saint-Sauveur Trésor' },
    home: { nl: 'Sint-Salvators', en: 'St Saviour\'s', fr: 'Saint-Sauveur' },
    //rooms: { nl: 'Zalen', en: 'Rooms', fr: 'Salles' },
    treasury: { nl: 'Schatkamer', en: 'Treasury', fr: 'Trésor' },
    room: { nl: 'Zaal', en: 'Room', fr: 'Salle' },
    artwork: { nl: 'Kunstwerk', en: 'Artwork', fr: 'Œuvre d\'art' },
    // artworks: { nl: 'Kunstwerken', en: 'Works of art', fr: 'Œuvres d\'art' },
    //cathedral: { nl: 'Kathedraal topwerken', en: 'Cathedral top works', fr: 'Cathédrale meilleures œuvres' },
    infoPoints: { nl: 'Infopunten', en: 'Infopoints', fr: 'Points d\'info' },
    infoPoint: { nl: 'Infopunt', en: 'Infopoint', fr: 'Point d\'info' },
    paintings: { nl: 'Schilderijen kooromgang', en: 'Paintings ambulatory', fr: 'Tableaux déambulatoire' },
    painting: { nl: 'Kooromgang schilderij', en: 'Ambulatory painting', fr: 'Déambulatoire tableau' },
    scan: { nl: 'Scan QR-code', en: 'Scan QR code', fr: 'Scannez code QR' },
    artworkNumber: { nl: 'Kies nr. kunstwerk Schatkamer', fr: 'Saisissez No. d\'œuvre Trésor', en: 'Enter artwork No. Treasury' },
    language: { nl: 'Taalkeuze', fr: 'Choix de langue', en: 'Language selection' },
    usage: { nl: 'Kies website of app installeren', en: 'Choose website or install app', fr: 'Choisissez site web ou installer l\'application' },
    install: { nl: 'Installeer de app', en: 'Install the app', fr: 'Installez l\'application' },
    updating: { nl: 'Even geduld', en: 'Please be patient', fr: 'Soyez patient' },
}

module.exports.messages = {
    usage: {
        nl: 'U kan de app gebruiken als website of als app geïnstalleerd op uw toestel.',
        en: 'You can use the app as website or as app installed on your device.',
        fr: 'Vous pouvez utiliser l\'application comme site web ou comme application installée sur votre appareil.'
    },
    install_safari: {
        nl: 'In Safari klik $ en dan $ Zet op beginscherm',
        en: 'In Safari tap $ and then $ Add to home screen',
        fr: 'Dans Safari tapez $ et puis $ Sur l\'écran d\'accueil'
    },
    install_chrome: {
        nl: "Klik 'Toevoegen Schatkamer aan startscherm'$of klik Chrome menu $en dan $ om te installeren.",
        en: "Tap 'Add Treasury to home screen'$or tap Chrome menu $and then $ to install.",
        fr: "Tapez 'Ajouter Trésor à l'écran d'accueil'$ou le menu Chrome $et puis $ pour installer."
    },
    inform_home_screen_icon: {
        nl: "Open daarna de app via het icoon op uw startscherm.",
        en: "Then open the app via the icon on your home screen.",
        fr: "Ensuite, ouvrez l'application via l'icône sur votre écran d'accueil."
    },
    updating: {
        nl: 'St.-Salvators app wordt geupdate. Dit duurt hoogstens één minuut.',
        en: 'St Saviour’s app is being updated. This will take no more than one minute.',
        fr: 'L\'application St.-Sauveur est en cours de mise à jour. Cela ne prendra pas plus d\'une minute.'
    },
}

/** DEPRECATED - moved to home.yaml
module.exports.home = {
    title_app_usage: {
        nl: 'Hoe de app te gebruiken',
        en: 'How to use the app',
        fr: 'Utilisation de l\'app'
    },
    message_icon_language: {
        nl: 'Om uw taalkeuze te wijzigen, klik het icoon rechts boven in uw scherm.',
        en: 'To change your language selection, click the icon at the top right of your screen.',
        fr: 'Pour modifier votre choix de langue, cliquez sur l\'icône en haut à droite de votre écran.'
    },
    message_icon_rooms: {
        nl: 'Overzicht van alle zalen gerangschikt per letter A-J.<br/>Selecteer zaal op letter en vervolgens kunstwerk op nummer om de informatie op te vragen.',
        en: 'Overview of all rooms arranged by letter A-J.<br/>Select room by letter and then artwork by number to retrieve the information.',
        fr: 'Aperçu de toutes les salles classées par lettre A-J.<br/>Sélectionnez la salle par lettre, puis l\'œuvre d\'art par numéro pour retrouver l\'information.'
    },
    message_icon_artworks: {
        nl: 'Overzicht van alle kunstwerken gerangschikt per nummer 1-87.<br/>Selecteer kunstwerk op nummer om de informatie op te vragen.',
        en: 'Overview of all artworks ordered by number 1-87.<br/>Select artwork by number to retrieve the information.',
        fr: 'Aperçu de toutes les œuvres d\'art classées par numéro 1-87.<br/>Sélectionnez l\'œuvre d\'art par numéro pour retrouver l\'information.'
    },
    message_icon_artwork_number: {
        nl: 'Voer het kunstwerknummer in om de informatie op te vragen.',
        en: 'Enter the artwork number to retrieve the information.',
        fr: 'Saisissez le numéro de l\'œuvre pour récupérer l\'information.'
    }
}
 */

module.exports.buttons = {
    next: { nl: 'Ga verder', en: 'Continue', fr: 'Continuez' },
    previous: { nl: 'Ga terug', en: 'Go back', fr: 'Retour' },
    save: { nl: 'Bewaar', en: 'Save', fr: 'Sauvez' },
    to_website: { nl: 'Website', en: 'Website', fr: 'Site web' },
    to_install: { nl: 'Installeer', en: 'Install', fr: 'Installez' }
}

module.exports.metadata_labels = {
    creator: { nl: 'Vervaardiger:', en: 'Creator:', fr: 'Créateur:' },
    period: { nl: 'Periode:', en: 'Period:', fr: 'Période:' },
    material: { nl: 'Materiaal:', en: 'Material:', fr: 'Matériau:' },
    dimensions: { nl: 'Afmetingen:', en: 'Dimensions:', fr: 'Dimensions:' },
    origin: { nl: 'Herkomst:', en: 'Origin:', fr: 'Origine:' },
    nbr_artworks: { nl: 'Aantal kunstwerken:', fr: 'Nombre d\'œuvres:', en: 'Number of artworks:' }
}

module.exports.store = {
    CACHED: 'SSK_CACHED',
    LANGUAGE: 'SSK_APP_LANG',
    // LOCALE: 'locale',
    NAVIGATION_LINK: 'SSK_APP_NAV_LINK',
    // SERVICE_WORKER_READY: 'serviceWorkerReady',
    // SERVICE_WORKER_INSTALLED: 'serviceWorkerInstalled',
    // OFFLINE_NOTIFICATION_SHOWN: 'offlineNotificationShown', // will not be shown for now
    // YES: 'yes',
    // NO: 'no',
}

module.exports.choose_language = { nl: 'Kies uw taal', fr: 'Choisissez votre langue', en: 'Choose your language' }
module.exports.unknown = { nl: 'onbekend', fr: 'inconnu', en: 'unknown' }
module.exports.read_more = { nl: 'Lees verder', fr: 'Lire la suite', en: 'Read more' }
module.exports.not_found = { nl: 'Pagina niet gevonden', fr: 'Page non trouvée', en: 'Page not found' }
module.exports.church_photography = { nl: '© Kerkfotografie', fr: '© Photographie d\'église', en: '© Church Photography' }
