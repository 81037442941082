import React, { useContext, useEffect } from 'react'

// @ts-ignore
import { navigate } from 'gatsby'

import { AppContext } from './components/ContextProvider'
import { store } from './constants'

const isEnvDevelopment = () => {
    // NODE_ENV == "development" OR "production"
    const { NODE_ENV } = process.env
    const isEnvDevelopment = NODE_ENV === 'development'
    //console.log('* utils isEnvDevelopment - NODE_ENV['+JSON.stringify(NODE_ENV)+'] isEnvDevelopment['+isEnvDevelopment+']')
    return isEnvDevelopment
}

// isServiceWorkerSupported() checks if current browser supports serviceworker
//      https://developer.mozilla.org/en-US/docs/Web/API/Navigator/serviceWorker
//      https://caniuse.com/?search=serviceworker e.g. Safari 11.1+
const isServiceWorkerSupported = () => {

    let swSupported = false

    if(typeof navigator !== 'undefined') {
        // check whether the browser supports service workers, also possible: if(navigator.serviceWorker)
        if('serviceWorker' in navigator) {
            swSupported = true
            console.log('# Utils isServiceWorkerSupported - TRUE')
        }
        else {
            console.log('# Utils isServiceWorkerSupported - FALSE')
        }
    }
    return swSupported
}

// PROD RUNTIME isStandalone() checks whether app is running in standalone mode
const isStandalone = () => {

    let standalone = false

    // PROD RUNTIME check
    // build check window undefined since "window" is not available during server side rendering (=== PROD build?).
    if(!isEnvDevelopment() && typeof window !== 'undefined') {

        const logPrefix = '# Utils PROD isStandalone - '

        // (!) debug logging
        console.log(logPrefix + 'window.navigator undefined? ['+(typeof window.navigator === 'undefined')+']')
        if(window.navigator.hasOwnProperty('standalone')) {
            // @ts-ignore
            console.log(logPrefix + 'window.navigator.standalone['+window.navigator.standalone+']')
        }
        else {
            console.log(logPrefix + 'property standalone NOT AVAILABLE on window.navigator')
        }

        // (!) TODO: TEST how to detect if app in standalone on Chrome, Firefox, Safari, Opera, Edge
        // https://stackoverflow.com/questions/17989777/detect-if-ios-is-using-webapp/40932301#40932301
        // https://stackoverflow.com/questions/21125337/how-to-detect-if-web-app-running-standalone-on-chrome-mobile
        // https://stackoverflow.com/questions/51735869/check-if-user-has-already-installed-pwa-to-homescreen-on-chrome
        // https://www.reddit.com/r/gatsbyjs/comments/iehg73/help_request_how_can_i_check_if_my_site_has/
        // https://stackoverflow.com/questions/21125337/how-to-detect-if-web-app-running-standalone-on-chrome-mobile

        // iOS browser Safari uses 'standalone' property on 'navigator'
        // todo: test Safari and what about Opera?
        // @ts-ignore
        const isStandaloneIOS = window.navigator.hasOwnProperty('standalone') && window.navigator.standalone
        // alternative to test: ('standalone' in window.navigator) && (window.navigator.standalone)
        // todo: test Firefox and Chrome
        // Be careful with window.matchMedia('(display-mode: standalone)').matches: it is also true in desktop Chrome popup windows
        const isStandaloneChrome = window.matchMedia('(display-mode: standalone)').matches
        standalone = isStandaloneChrome || isStandaloneIOS

        console.log(logPrefix + 'isStandaloneChrome['+isStandaloneChrome+'] isStandaloneIOS['+isStandaloneIOS+'] standalone['+standalone+']')
    }
    return standalone
}

// isIos() detects if device is on iOS
const isIOS = () => {

    let iOS = false
    // PROD build check since navigator not available during server side rendering (PROD build)
    if(typeof navigator !== 'undefined') {
        // (!) userAgent check possibly not reliable
        //     cfr. https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
        // const userAgent = window.navigator.userAgent.toLowerCase()
        // iOS = /iphone|ipad|ipod/.test(userAgent)
        // platform check solution from https://www.geeksforgeeks.org/detect-a-device-is-ios-or-not-using-javascript/
        // for alternative iOS check see https://medium.com/swlh/a-simple-react-hook-to-prompt-ios-users-to-install-your-wonderful-pwa-4cc06e7f31fa
        iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
        console.log('# Utils isIOS() - iOS['+iOS+'] navigator.platform['+navigator.platform+']')
    }
    // todo temp Safari debug alert check
    // if(typeof window !== 'undefined') window.alert('iOS['+iOS+']')
    return iOS
}

/**
 isOldSafariNoPWA
 (!) 18/6/2021 BAD PRACTICE & NOT RELIABLE, THEREFORE NOT IMPLEMENTED
 solution from https://www.seanmcp.com/articles/how-to-get-the-browser-version-in-javascript/
        ( other: https://stackoverflow.com/questions/5916900/how-can-you-detect-the-version-of-a-browser )
 NEED TO 'SNIFF' the userAgent
    see related articles at bottom of that article, e.g. https://css-tricks.com/browser-detection-is-bad/
 (!) BAD PRACTICE AND NOT RELIABLE - pre Safari 11.1 shows A2HS button, but this not same as standalone, NO serviceWorker(!)
 */
// const isOldSafariNoPWA = () => {
//     let isOldSafari = false
//     if(!isIOS()) return false
//     // TO IMPLEMENT
//     return isOldSafari
// }

/**
 linkManifestFile()
 solution from https://medium.com/@alshakero/how-to-setup-your-web-app-manifest-dynamically-using-javascript-f7fbee899a61
 links PWA language specific (NL/FR/EN) manifest file dynamically from static folder in head link #manifest-placeholder (html.tsx):
 - when user selects language (LanguageChooser.tsx)
 - at page reload or PWA re(-start) the file needs to be re-linked (via ContextProvider state update call)
   (!) necessary to support LightHouse run (Chrome Dev Tools) which always reloads page
   (!) LIGHTHOUSE PWA TESTS ARE TO BE EXPECTED BY ANY 3rd PARTY

 TODO: implement similar solution for other language specific head meta tags:
 <meta name="apple-mobile-web-app-title" content="St.-Salvators"/> & 10 x apple-touch-startup-image
 */
const linkManifestFile = () => {
    const storedLanguage = localStorage.getItem('language') as string
    console.log('# Utils linkManifestFile() - storedLanguage[' + storedLanguage + ']')
    const language = !!storedLanguage ? storedLanguage.toLowerCase() : 'nl'
    const manifestFilename = `/manifest_${language}.json`
    console.log('# Utils linkManifestFile() - manifestFilename[' + manifestFilename + '] to load')
    const manifestLink = document.querySelector('#manifest-placeholder')
    // @ts-ignore
    manifestLink.setAttribute('href', manifestFilename)
    return true
}

/**
 Custom React Hook to read current navigationLink from AppContext

 (!) REMARK 11/3/2021
 'Error: Invalid hook call. Hooks can only be called inside of the body of a function component.'
 This error occurs @runtime on line 'context = useContext(AppContext)' when called from
 an onClick event like changeLanguage(), Gatsby Link onclick (both tested)
 CONCLUSION: call only inside of the body of a function component, not from an Event.
 */
const useNavigationLink: () => { previousLink: string, previousScrollTop: number, currentLink: string, currentScrollTop: number }  = () => {

    // console.log(`# useNavigationLink CALLED`)
    const context = useContext(AppContext)

    // (!) undefined checks to enable 'gatsby build' context null
    if(!context || !context.navigationLink) {
        // console.error(`# useNavigationLink ${!context ? 'context' : 'context navigationLink'} undefined`)
        return { previousLink: '', previousScrollTop: 0, currentLink: '', currentScrollTop: 0 }
    }
    return context.navigationLink
}

// Custom React Hook to scroll to navigationLink currentScrollTop
const useScrollToCurrentScrollTop  = () => {

    const { currentScrollTop } = useNavigationLink()

    /**TODO NOT VERY CLEAR 'useEffect dependencies array' - google & read more
     ANY prop that is accessed in useEffect should be declared in the dependencies array
     goal: prevent useEffect calls at re-render component (e.g. via parent re-render) and no change to those dependencies
     https://medium.com/better-programming/understanding-the-useeffect-dependency-array-2913da504c44
     https://egghead.io/lessons/react-manage-the-useeffect-dependency-array
     https://reactjs.org/docs/hooks-faq.html
     */
    useEffect(() => {
        // console.log('# useScrollToCurrentScrollTop - ' +
        //     'window.pageYOffset['+window.pageYOffset+'] navigationLink.currentScrollTop['+currentScrollTop+']')
        const scrollOptions = { top: currentScrollTop }
        window.scrollTo(scrollOptions)
        // window.scrollBy(0, navigationLink.currentScrollTop)
    }, [])
}

const navigateTo = (to: string) => {
    if(typeof window !== 'undefined') navigate(to)
}

const saveCacheFlagToStore = () => {
    if(typeof localStorage !== 'undefined') localStorage.setItem(store.CACHED, 'cached')
}

const isCacheFlaggedInStore = () => {
  let cacheFlagged = false
  if(typeof localStorage !== 'undefined') {
    cacheFlagged = localStorage.getItem(store.CACHED) as string !== null
  }
  return cacheFlagged
}

const saveLanguageToStore = (language: string) => {
    if(typeof localStorage !== 'undefined') localStorage.setItem(store.LANGUAGE, language.toUpperCase())
}

const getLanguageFromStore = () => {
    return typeof localStorage !== 'undefined' ? localStorage.getItem(store.LANGUAGE) as string : null
}

/**
 * (!) translate() is called at each page render via t()
 * (!) the case of no language on store is handled by the Layout.tsx useEffect() redirect to index.tsx
 */
const translate = (content: object, key: string) => {

    // (!) not using/checking ContextProvider 'language' state for now
    const storedLanguage = getLanguageFromStore()
    const lang = !!storedLanguage ? storedLanguage.toLowerCase() : ''

    // @ts-ignore
    const result =  !!key ? content[key][lang] : content[lang]
    // console.log('# result == ' + result)
    // return !lang || lang.length === 0 ? 'NO LANG' : !result ? 'NO TRANSLATE RESULT' : result
    return !!result ? result : ''
}

// t() function as in https://react.i18next.com/getting-started
const t = (content: object) => {
    // @ts-ignore
    return translate(content)
}

// th() translates to html
// (!) ISSUE dangerouslySetInnerHTML: see comments in home.tsx useEffect()
// const th = (content: object) => {
//     // @ts-ignore
//     const html = translate(content)
//     // console.log('# utils th - content['+JSON.stringify(content)+'] html['+html+']')
//     return (
//         <p dangerouslySetInnerHTML={{ __html: html }}/>
//     )
// }

const t_ = (content: object, key: string) => {
    return translate(content, key)
}

export {
  isEnvDevelopment,
  isServiceWorkerSupported,
  isStandalone,
  isIOS,
  // isOldSafariNoPWA, // BAD PRACTICE
  linkManifestFile,
  useNavigationLink,
  useScrollToCurrentScrollTop,
  navigateTo,
  saveLanguageToStore,
  getLanguageFromStore,
  saveCacheFlagToStore,
  isCacheFlaggedInStore,
  t,
  t_
}
